const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const Platform = require("../Platform");
const Utils = require("../Utils");
const Global = require("../Config/Global");
const ViewerDataInfo = require("./ViewerDataInfo");
const GCSController = require("./GCSController");
const ViewerNotificationsPanel = require("../UI/ViewerNotificationsPanel");
const NotificationType = require("../Info/NotificationType");
const queryString = require("query-string");
const service = {};

const IDLE_STATE = 0;
const ACTIVE_STATE = 1;
const WAITING_STATE = 2;
const CONTENT_STATE = 3;

const PREVIOUS_LOCAL_IP_KEY = "previous-local-ip";
const DATA_SOURCE_PARAM = "dataSource=";
const DATA_SOURCE_CORE = "core";
// Unused, added for completeness
// const DATA_SOURCE_GCS = "gcs";

let state = IDLE_STATE;
let apiDelay = 1;

const PLAYER_CONFIGURATION_LOG_RETRY_INTERVAL = 5 * 60 * 1000;
let playerConfigurationLogRetryTimeout = null;

service.REASON = {
  RETRIEVAL_TIMEOUT: "RETRIEVAL_TIMEOUT",
  UPDATE_MESSAGE_RECEIVED: "UPDATE_MESSAGE_RECEIVED",
  VIEWER_INIT: "VIEWER_INIT",
  POLLING_TIMER: "POLLING_TIMER",
  PREVIEW: "PREVIEW"
};

const _reportDataReady = function (result) {
  state = ACTIVE_STATE;
  apiDelay = 1;

  window.RiseVision.Viewer.Data.ViewerDataController.reportDataReady(result);
};

const _isDataSourceCore = () => {
  const dataSource = Utils.getFromQueryString(DATA_SOURCE_PARAM);

  if (!ConfigurationFactory.isDisplay() && !ConfigurationFactory.isSharedschedule()) {
    return true;
  } else if (dataSource === DATA_SOURCE_CORE) {
    return true;
  } else {
    return false;
  }
};

const _getData = function (url) {
  if (!jQuery || !jQuery.getJSON) { return; }

  if (_isDataSourceCore()) {
    return _getFromCore(url);
  }

  Logger.log("Retrieving Viewer Data");

  GCSController.getContent((result) => {
    if (!result || !result.content) {
      return _getFromCore(url);
    }

    _reportDataReady(result);
  });
};

const _getFromCore = function (url) {
  jQuery.getJSON(url, (result) => {
    try {
      Logger.log("Viewer Data - Status Message - " + result.status.message);

      _reportDataReady(result);
    }
    catch (err) {
      Logger.logException("ViewerDataProvider._getData", err, url, result);
    }
  })
  .fail((jqXHR, textStatus, error) => {
    Logger.logException("ViewerDataProvider._getData.fail", error, url, textStatus);
  });
};

const _dataTimerExecute = function () {
  if (state === WAITING_STATE) {
    if (!ViewerDataInfo.hasData()) {
      ViewerNotificationsPanel.showError(NotificationType.SERVER_CONNECTION_FAILED, false, apiDelay * 60);
    }

    state = CONTENT_STATE;
  }

  // signifies last attempt to call the Viewer API failed, so we will inform the user of it.
  if (state === CONTENT_STATE) {
    state = IDLE_STATE;

    service.retrieveData(service.REASON.RETRIEVAL_TIMEOUT + ` API Delay: ${apiDelay} min`);
  }
  else {
    state = IDLE_STATE;
  }
};

const _loadSysInfo = function( playerConfig ) {
  const sysInfo = ConfigurationFactory.getSysInfo() || "";

  if( sysInfo ) {
    try {
      const info = queryString.parse(decodeURIComponent(sysInfo));

      playerConfig.os_description = info.os || "";
      playerConfig.installer_version = info.iv || "";
      playerConfig.player_name = info.pn || "";
      playerConfig.java_version = info.jv || "";
      playerConfig.player_version = info.pv || "";
      playerConfig.cache_version = info.ev || "";
      playerConfig.time_zone = info.time_zone || "";
      playerConfig.utc_offset = info.utc_offset || "";
      playerConfig.manufacturer = info.manufacturer || "";
      playerConfig.product_name = info.product_name || "";
      playerConfig.local_ip = info.local_ip || "";
      playerConfig.mac = info.mac || "";
      playerConfig.build_type = info.build_type || "";
      playerConfig.machine_id = info.machine_id || "";
      playerConfig.app_id = info.app_id || "";
      playerConfig.hostname = info.hostname || "";
      playerConfig.cpu = info.cpu || "";
      playerConfig.serial_number = info.sn || "";
      playerConfig.browser_name = info.bn || "";
      playerConfig.browser_version  = info.bv || "";
      playerConfig.notification_id  = info.pushy_device_token || "";
      playerConfig.firmware_version = info.fv || "";
    } catch (e) {
      Logger.log("Player Data - No system info");
    }
  } else {
    Logger.log("Player Data - No system info");

    if( ConfigurationFactory.isWebPlayer() ) {
      playerConfig.player_name = "Web Player";
      playerConfig.player_version = Global.VIEWER_VERSION;
    }
  }
}

const _getPlayerConfigData = function () {
  const playerConfig = {};

  _loadSysInfo( playerConfig );

  playerConfig.viewer_version = Global.VIEWER_VERSION;
  playerConfig.width = ConfigurationFactory.getWindowWidth();
  playerConfig.height = ConfigurationFactory.getWindowHeight();

  if (!playerConfig.browser_name && !playerConfig.browser_version) {
    playerConfig.browser_name = Platform.getBrowserName();
    playerConfig.browser_version = Platform.getBrowserVersion();
  }

  if (playerConfig.local_ip) {
    localStorage.setItem(PREVIOUS_LOCAL_IP_KEY, playerConfig.local_ip);
  } else if( !ConfigurationFactory.isWebPlayer() ) {
    playerConfig.local_ip = localStorage.getItem(PREVIOUS_LOCAL_IP_KEY) || "";
  }

  return playerConfig;
};

const _logConfigData = function (apiUrl, cb) {
  const playerConfigData = _getPlayerConfigData();
  let storedConfigData = null;

  try {
    storedConfigData = JSON.parse(localStorage.getItem("playerConfig"));
  } catch (e) {
    Logger.log("No player config data in local storage");
  }

  if (!storedConfigData || JSON.stringify(playerConfigData) !== JSON.stringify(storedConfigData)) {
    Logger.logPlayerConfigData(playerConfigData, (insertSuccess) => {
      if (insertSuccess) {
        try {
          localStorage.setItem("playerConfig", JSON.stringify(playerConfigData));
        } catch (e) {
          Logger.logExternalMessage("Player config data cache set fail", e.message);
          Logger.viewerWarning("Player config data cache set fail", e.message);
        }
      } else if(ConfigurationFactory.isDisplay() || ConfigurationFactory.isWebPlayer()) {
        _retryLogPlayerConfigurationData(playerConfigData);
      }

      cb && cb(apiUrl);
    });
  } else {
    Logger.log("Player config data has not changed");
    cb && cb(apiUrl);
  }
};

const _retryLogPlayerConfigurationData = function(playerConfigData) {
  Logger.log(`Player config data save failed - will retry after ${ PLAYER_CONFIGURATION_LOG_RETRY_INTERVAL } ms.`);
  Logger.viewerError( "E999999999", "Player config data save failed", JSON.stringify(playerConfigData) );

  playerConfigurationLogRetryTimeout = setTimeout(() => {
    Logger.logPlayerConfigData(playerConfigData, (insertSuccess) => {
      if (insertSuccess) {
        localStorage.setItem("playerConfig", JSON.stringify(playerConfigData));
      } else {
        _retryLogPlayerConfigurationData(playerConfigData);
      }
    });
  }, PLAYER_CONFIGURATION_LOG_RETRY_INTERVAL);
}

service.logConfigData = function() {
  _logConfigData()
}

service.retrieveData = function (reason) {
  if (window.disableViewerContentFetch) { return; }
  if (ConfigurationFactory.isWebPlayer()) { return; }

  if (state === IDLE_STATE) {
    let url;

    url = Global.DATA_SERVER_URL.replace("{0}", ConfigurationFactory.getType());
    url = url.replace("{1}", ConfigurationFactory.getId());

    const sysInfo = ConfigurationFactory.getSysInfo() || "";
    const browserName = Platform.getBrowserName();
    const browserVersion = Platform.getBrowserVersion();

    const fullUrl = url +
        "?sig=" + ViewerDataInfo.getSig() +
        "&" + Global.VIEWER_URL_IDENTIFIER +
        (sysInfo ? "&" + decodeURIComponent(sysInfo) : "") +
        "&cn=" + encodeURIComponent(browserName) +
        "&cv=" + browserVersion +
        "&width=" + window.innerWidth +
        "&height=" + window.innerHeight;

    state = WAITING_STATE;

    // start 60 second timer for timeout of data retrieval
    setTimeout(_dataTimerExecute, apiDelay * Global.MINUTE_UPDATE_INTERVAL);

    apiDelay = Math.min(apiDelay * 2, ViewerDataInfo.getPollInterval());

    _logConfigData(fullUrl, _getData);
  }
  else {
    state = CONTENT_STATE;
  }
};

service.reset = function() {
  if (playerConfigurationLogRetryTimeout) {
    clearTimeout(playerConfigurationLogRetryTimeout);
    playerConfigurationLogRetryTimeout = null;
  }
}

module.exports = service;
