const PersistentNotificationType = require("../Info/PersistentNotificationType");

class PersistentNotificationPanel {
  constructor() {
    this.joinCode = "";
    this.container = document.getElementById("persistent-notification");
  }

  _render() {
    let title, text, messageTitle, messageText, footerIcon;
    if (this.joinCode) {
      title = "Screen sharing is available";
      text = "To start screen sharing, go to the following address and enter the code";

      if (this.notificationType === PersistentNotificationType.NO_PRESENTATION_SCHEDULED ||
        this.notificationType === PersistentNotificationType.NO_SCHEDULE) {
        messageTitle = "Scheduled digital content will resume when screen sharing ends.";
        messageText = "No scheduled content? Schedule your digital signage content now to get the most out of your display.";
      } else if (this.notificationType === PersistentNotificationType.NO_DISPLAY_LICENSE) {
        messageTitle = "No digital signage content is scheduled on this display.";
        messageText = "Want to display content? Add a digital signage license to this display.";
      }

      footerIcon = "";
    } else if (this.notificationType === PersistentNotificationType.NO_PRESENTATION_SCHEDULED ||
      this.notificationType === PersistentNotificationType.NO_SCHEDULE) {
      title = "No Content Scheduled";
      text = "<p>There is currently no digital signage content scheduled on this display.</p><p>Please schedule content to display here.</p>";
      // messageTitle = "Interested in screen sharing?";
      // messageText = "Add a screen sharing license to this display.";
      // footerIcon = "screen-sharing";
    } else if (this.notificationType === PersistentNotificationType.NO_TEMPLATE_SCHEDULED) {
      title = "There are no updates to show right now.";
      text = "Have a great day!";
    } else if (this.notificationType === PersistentNotificationType.NO_DISPLAY_LICENSE) {
      title = "Missing Display License";
      text = "<p>No digital signage content is scheduled on this display.</p><p>Want to display content? Add a digital signage license to this display.</p>";
    }

    const htmlTitle = document.getElementById('persistent-notification-title');
    const htmlText = document.getElementById('persistent-notification-text');
    const htmlUrl = document.getElementById('persistent-notification-url');
    const htmlCode = document.getElementById('persistent-notification-code');
    const htmlFooter = document.getElementById('persistent-notification-footer');
    const htmlMessageTitle = document.getElementById('persistent-notification-message-title');
    const htmlMessageText = document.getElementById('persistent-notification-message-text');
    const htmlFrame = document.getElementById('persistent-notification-frame');
    const htmlIcon = document.getElementById('persistent-notification-icon');

    if (htmlText) htmlTitle.innerHTML = title || "";
    if (htmlText) htmlText.innerHTML = text || "";
    if (htmlUrl) htmlUrl.innerHTML = "risevision.com/join";
    if (htmlMessageTitle) htmlMessageTitle.innerHTML = messageTitle || "";
    if (htmlMessageText) htmlMessageText.innerHTML = messageText || "";
    if (htmlFrame) {
      if (this.joinCode) {
        htmlFrame.style.display = 'block';
        if (htmlCode) htmlCode.innerHTML = this.joinCode;
      } else {
        htmlFrame.style.display = 'none';
      }
    }
    if (footerIcon) {
      if (htmlFooter) htmlFooter.classList.add('footer-icon');
      if (htmlIcon) {
        if (footerIcon === 'screen-sharing') {
          htmlIcon.classList.add('green-icon');
          htmlIcon.innerHTML = '<img src="images/screen-sharing-green.svg" width="24" height="24">';
        } else {
          htmlIcon.classList.remove('green-icon');
          htmlIcon.innerHTML = '';
        }
      }
    } else {
      if (htmlFooter) htmlFooter.classList.remove('footer-icon');
      if (htmlIcon) htmlIcon.innerHTML = '';
    }
  }

  setJoinCode(joinCode) {
    this.joinCode = joinCode;

    this._render();
  }

  show(notificationType) {
    this.notificationType = notificationType;

    this.container.style.display = "block";

    this._render();
  }

  hide() {
    this.container.style.display = 'none';
  }
}

PersistentNotificationPanel.show = (notificationType) => { return _getInstance().show(notificationType); };
PersistentNotificationPanel.hide = () => { return _getInstance().hide(); };
PersistentNotificationPanel.setJoinCode = (content) => { _getInstance().setJoinCode(content); };

let instance;

function _getInstance() {
  if (!instance) {
    instance = new PersistentNotificationPanel();
  }

  return instance;
}

module.exports = PersistentNotificationPanel;
