const DisplayControlConfigurationLogging = require("./DisplayControlConfigurationLogging");
const RebootScheduler = require("./RebootScheduler");
const ConfigurationFactory = require("../ConfigurationFactory");
const ContentSentinelDispatcher = require("content-sentinel");
const Logger = require("../Logger");
const Utils = require("../Utils");

const service = {};
const ONE_MINUTE = 60000;

service.getInjectedPlayer = () => {
  if (typeof AndroidPlayer !== 'undefined') {
    return AndroidPlayer;
  } else if (window.RisePlayer) {
    return window.RisePlayer;
  }

  Logger.viewerWarning( "Injected player object is not available" );

  return null
}

service.configureHeartBeatInterval = () => {
  const interval = Logger.heartbeatInterval();

  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player) {
      try {
        Logger.viewerDebug( `Player set heartbeat interval ${ interval }` );

        player.setHeartbeatInterval( `${ interval }` );
      } catch( error ) {
        Logger.viewerError( "E999999999", "Player set heartbeat interval invocation failed", error.message );
      }
    }
  }
}

service.bindPlayerUpdateButton = () => {
  const button = document.querySelector("#player-update-modal button");
  const modal = document.querySelector("#player-update-modal");

  if (!button || !modal || typeof AndroidPlayer === 'undefined') {return}

  button.onclick = ()=>{
    modal.innerHTML = "Retrieving update, please wait."
    setTimeout(()=>AndroidPlayer.requestPlayerUpdate());
    button.onclick = ()=>{}
  };
}

service.processContentJson = json => {
  if (!json) {return;}

  if (json.display) {
    if (typeof json.display.orientation === 'number') {
      service.setOrientation(json.display.orientation)
    }
    if (typeof json.display.autostartOnBoot === 'boolean') {
      service.setAutoStartOnBoot(json.display.autostartOnBoot)
    }

    const isRebootablePlayer = ConfigurationFactory.isGiadaDN74() || ConfigurationFactory.isAvocorRSeries();

    const action = window.playerSupportsReboot || isRebootablePlayer ?
      service.requestReboot : service.requestRestart;

    // wait one minute before scheduling, so it doesn't reboot multiple times
    setTimeout(()=>{
      RebootScheduler.scheduleReboot(json, action);
    }, ONE_MINUTE);
  }

  return json;
}

service.setOrientation = rot => {
  // Apps/core set orientation as rotation from landscape (i.e. 0 is landscape, 90 is portrait)
  if (!Number.isInteger(rot)) {return;}

  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.setScreenOrientation) {
      try {
        Logger.viewerDebug( `Setting orientation to ${rot}` );

        player.setScreenOrientation(rot);
      } catch( error ) {
        Logger.viewerError( "E999999999", "Player set orientation invocation failed", error.message );
      }
    }
  }
}

service.setAutoStartOnBoot = value => {
  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.setAutoStartOnBoot) {
      try {
        Logger.viewerDebug( `Setting autoStart to ${value}` );

        player.setAutoStartOnBoot(value);
      } catch( error ) {
        Logger.viewerError( "E999999999", "Player set autoStartOnBoot invocation failed", error.message );
      }
    }
  }
}

service.requestReboot = () => {
  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.requestReboot) {
      try {
        Logger.viewerDebug( `Request player reboot` );

        service.flushLocalStorageToDisk();
        player.requestReboot();
      } catch( error ) {
        Logger.viewerError( "E999999999", "Request player reboot invocation failed", error.message );
      }
    }
  }
}

service.requestRestart = (useEndpointLogs = true) => {
  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    // When cancelling startup, a displayId will not be available and remote Logger would fail
    const logDebug = useEndpointLogs ? Logger.viewerDebug : console.log;
    const logError = useEndpointLogs ? Logger.viewerError : console.error;
    const player = service.getInjectedPlayer();

    if (player) {
      try {
        logDebug( `Request player restart` );

        service.flushLocalStorageToDisk();
        player.requestRestart();
      } catch( error ) {
        logError( "E999999999", "Request player restart invocation failed", error.message );
      }
    }
  } else if (ConfigurationFactory.isWebPlayer()) {
    Utils.reloadViewer();
  }
}

service.requestUpgrade = () => {
  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.requestUpgrade) {
      try {
        Logger.viewerDebug( `Request player upgrade` );

        player.requestUpgrade();
      } catch( error ) {
        Logger.viewerError( "E999999999", "Request player upgrade invocation failed", error.message );
      }
    }
  }
}

service.setDisplayId = displayId => {
  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const logDebug = !!displayId ? Logger.viewerDebug : console.log;
    const logError = !!displayId ? Logger.viewerError : console.error;
    const player = service.getInjectedPlayer();

    if (player && player.setDisplayId) {
      try {
        logDebug( `Set display id: ${displayId}` );

        player.setDisplayId(displayId);
      } catch( error ) {
        logError( "E999999999", "Set player displayId invocation failed", error.message );
      }
    }
  }
}

service.setCompanyId = companyId => {
  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.setCompanyId) {
      try {
        Logger.viewerDebug( `Set company id: ${companyId}` );

        player.setCompanyId(companyId);
      } catch( error ) {
        Logger.viewerError( "E999999999", "Set player companyId invocation failed", error.message );
      }
    }
  }
}

service.loadDisplayIdFromPlayerIfAvailable = () => {
  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.getDisplayId) {
      try {
        const displayId = player.getDisplayId();

        if (!displayId) {
          console.warn( "No display id found on player settings" );
          return;
        }

        console.log( `Loading display id from player: ${displayId}` );
        localStorage.setItem(ConfigurationFactory.WEBPLAYER_DISPLAY_ID_KEY, displayId);
      } catch( error ) {
        console.error( "getDisplayId player invocation failed", error.message );
      }
    }
  }
}

service.sendScreenshotSaved = clientId => {
  ContentSentinelDispatcher.sendControllerMessage({
    msg: "screenshot-saved", clientId
  }, window);
}

service.sendScreenshotFailed = clientId => {
  ContentSentinelDispatcher.sendControllerMessage({
    msg: "screenshot-failed", clientId
  }, window);
}

service.requestScreenshot = data => {
  if (!data || !data.url || !data.clientId) {
    return Logger.viewerError( "E999999999", "Invalid screenshot request", JSON.stringify(data) );
  }

  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player) {
      try {
        Logger.viewerDebug( `Request player screenshot` );

        player.requestScreenshot(data.url, data.clientId);
      } catch( error ) {
        Logger.viewerError( "E999999999", "Request player screenshot invocation failed", error.message );

        service.sendScreenshotFailed( data.clientId );
      }
    } else {
      service.sendScreenshotFailed( data.clientId );
    }
  }
}

service.setDisplayControlConfiguration = configuration => {
  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.setDisplayControlConfiguration) {
      try {
        DisplayControlConfigurationLogging.logConfiguration(configuration || '');

        player.setDisplayControlConfiguration(configuration);
      } catch( error ) {
        Logger.viewerError( "E999999999", "Set display control configuration invocation failed", error.message );
      }
    }
  }
}

service.turnDisplayOn = configuration => {
  Logger.viewerDebug( "turning display on" );

  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.turnDisplayOn) {
      try {
        player.turnDisplayOn();
      } catch( error ) {
        Logger.viewerError( "E999999999", "Turn display on invocation failed", error.message );
      }
    }
  }
}

service.turnDisplayOff = configuration => {
  Logger.viewerDebug( "turning display off" );

  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.turnDisplayOff) {
      try {
        player.turnDisplayOff();
      } catch( error ) {
        Logger.viewerError( "E999999999", "Turn display off invocation failed", error.message );
      }
    }
  }
}

service.notifyOfRegularContent = () => {
  window.dispatchEvent(new Event("notifyOfRegularContent"));

  if (ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    const player = service.getInjectedPlayer();

    if (player && player.notifyOfRegularContent) {
      try {
        player.notifyOfRegularContent();
      } catch( error ) {
        Logger.viewerError( "E999999999", "Notify of Regular Content invocation failed", error.message );
      }
    }
  }
}

service.playerMessageFn = (msg, data) => {
  if (msg === "reboot-request") {service.requestReboot()}
  if (msg === "restart-request") {service.requestRestart()}
  if (msg === "screenshot-request") {service.requestScreenshot(data)}
  if (msg === "upgrade-request") {service.requestUpgrade()}
};

service.flushLocalStorageToDisk = () => {
  const player = service.getInjectedPlayer();

  if (player && player.flushLocalStorageToDisk) {
    try {
      player.flushLocalStorageToDisk();
    } catch( error ) {
      Logger.viewerError( "E999999999", "Flush local storage to disk invocation failed", error.message );
    }
  }
};

module.exports = service;
