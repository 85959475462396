/* eslint global-require: 0 */

const RiseVision = {
  Viewer: {
    Config: {
      Config: require("./Viewer/Config/Config"),
      Global: require("./Viewer/Config/Global"),
    },
    Utils: require("./Viewer/Utils"),
    Logger: require("./Viewer/Logger"),
    ExtLogger: require("./Viewer/ExtLogger"),
    AnalyticsFactory: require("./Viewer/AnalyticsFactory"),
    ConfigurationFactory: require("./Viewer/ConfigurationFactory"),
    DuplicateIdHandler: require("./Viewer/DuplicateIdHandler"),
    EntryPoint: require("./Viewer/EntryPoint"),
    Info: {
      NotificationType: require("./Viewer/Info/NotificationType"),
      PersistentNotificationType: require("./Viewer/Info/PersistentNotificationType"),
      PlaylistItemInfo: require("./Viewer/Info/PlaylistItemInfo"),
    },
    Cache: {
      RiseCacheController: require("./Viewer/Cache/RiseCacheController"),
    },
    Player: {
      DisplayControlService: require("./Viewer/Player/DisplayControlService"),
      RisePlayerController: require("./Viewer/Player/RisePlayerController"),
      PlayerIntegration: require("./Viewer/Player/PlayerIntegration"),
      PlayerMessaging: require("./Viewer/Player/PlayerMessaging"),
    },
    Data: {
      ViewerDataInfo: require("./Viewer/Data/ViewerDataInfo"),
      ViewerDataController: require("./Viewer/Data/ViewerDataController"),
      ViewerDataProvider: require("./Viewer/Data/ViewerDataProvider"),
      ViewerInstanceController: require("./Viewer/Data/ViewerInstanceController"),
      GCSController: require("./Viewer/Data/GCSController"),
      ContentSentinelDataProvider: require("./Viewer/Data/ContentSentinelDataProvider")
    },
    Content: {
      TimerController: require("./Viewer/Content/TimerController"),
      ContentController: require("./Viewer/Content/ContentController"),
      ScheduleController: require("./Viewer/Content/ScheduleController"),
      PresentationController: require("./Viewer/Content/PresentationController"),
      HTMLTemplateController: require("./Viewer/Content/HTMLTemplateController"),
      PlaceholderController: require("./Viewer/Content/PlaceholderController"),
      PlaylistItemUtils: require("./Viewer/Content/PlaylistItemUtils"),
      PlaylistItemController: require("./Viewer/Content/PlaylistItemController"),
      WidgetController: require("./Viewer/Content/WidgetController"),
      GadgetController: require("./Viewer/Content/GadgetController"),
      PlaceholderVideoController: require("./Viewer/Content/PlaceholderVideoController"),
      VideoController: require("./Viewer/Content/VideoController"),
    },
    Parser: {
      HtmlParser: require("./Viewer/Parser/HtmlParser"),
      PresentationParser: require("./Viewer/Parser/PresentationParser"),
      DistributionParser: require("./Viewer/Parser/DistributionParser"),
      TimelineParser: require("./Viewer/Parser/TimelineParser"),
      GadgetSettingsParser: require("./Viewer/Parser/GadgetSettingsParser"),
    },
    Renderer: {
      ScheduleRenderer: require("./Viewer/Renderer/ScheduleRenderer"),
      PresentationRenderer: require("./Viewer/Renderer/PresentationRenderer"),
      HTMLTemplateRenderer: require("./Viewer/Renderer/HTMLTemplateRenderer"),
      HTMLTemplateMessaging: require("./Viewer/Renderer/HTMLTemplateMessaging"),
      TransitionHandler: require("./Viewer/Renderer/TransitionHandler"),
      AnimationHelper: require("./Viewer/Renderer/AnimationHelper"),
    },
    UI: {
      PopupPanel: require("./Viewer/UI/PopupPanel"),
      FloatingNotificationsController: require("./Viewer/UI/FloatingNotificationsController"),
      NavigationUIController: require("./Viewer/UI/NavigationUIController"),
      ScreenSharingUiController: require("./Viewer/UI/ScreenSharingUIController"),
      PersistentNotificationPanel: require("./Viewer/UI/PersistentNotificationPanel"),
      ViewerNotificationsPanel: require("./Viewer/UI/ViewerNotificationsPanel"),
      ViewerPreviewWidget: require("./Viewer/UI/ViewerPreviewWidget"),
      DisplayRegister: {
        DisplayRegisterWidget: require("./Viewer/UI/DisplayRegister/DisplayRegisterWidget"),
        EnterClaimIdWidget: require("./Viewer/UI/DisplayRegister/EnterClaimIdWidget"),
        EnterDisplayIdWidget: require("./Viewer/UI/DisplayRegister/EnterDisplayIdWidget"),
        HelpFrameWidget: require("./Viewer/UI/DisplayRegister/HelpFrameWidget"),
      },
      ViewerZoomController: require("./Viewer/UI/ViewerZoomController"),
    },
    Issues: {
      QuotaExceededIssueWorkaround: require("./Viewer/Issues/QuotaExceededIssueWorkaround")
    },
    Messaging: require("./Viewer/Messaging"),
    LocalMessaging: require("./Viewer/LocalMessaging"),
    Platform: require("./Viewer/Platform"),
    ViewerScripts: require("./Viewer/ViewerScripts"),
    ServiceWorker: require("./Viewer/ServiceWorker")
  },
};

window.RiseVision = RiseVision;

module.exports = RiseVision;
